import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from '../../store'
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Button,
  TextField,
  Alert,
  Link
} from '@mui/material';
import { useSearchParams } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';
import { StripePayment } from './StripePayment';
import InputAdornment from '@mui/material/InputAdornment';
import { useConfirm } from 'material-ui-confirm';
import {
  updateBudget,
  createBudget,
  fetchBillingData,
  setPaymentConfirmationPending
} from "../../lib/actions.js"
import { centsAsCurrency } from '../../lib/utils'
import { calculateFeeInCents, calculateActualBudgetInCents } from '../../lib/rates';


const dollarUSLocale = Intl.NumberFormat('de-DE', {
  style:"currency",
  currency: "EUR"
});

const toEuComma = (text) => {
  if (!text || text == "") {
    return text
  }
  return text.toString().replace(".", ",")
}

const toUsComma = (text) => {
  if (!text || text == "") {
    return text
  }
  return text.replace(",", ".")
}

export function Budget() {
  const confirm = useConfirm();
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
  const budget = useSelector((state) => state.dashboard.budget)
  const subscriptionStatus = useSelector((state) => state.account.value.subscriptionStatus)
  const nextPaymentDate = useSelector((state) => state.account.value.subscriptionNextPaymentDate)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [newBudget, setNewBudget] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()
  const [editBudget, setEditBudget] = useState(false)
  const [showSuspendForm, setShowSuspendForm] = useState(false)
  const [suspensionMonths, setSuspensionMonths] = useState(1)
  const [showStripeForm, setShowStripeForm] = useState(false)
  const redirectStatus = searchParams.get("redirect_status")

  const newBudgetAsCents = Math.round(newBudget * 100);
  const newBudgetAsCurrency = centsAsCurrency(newBudgetAsCents)

  const handleBudgetChange = (event) => {
    setNewBudget(toUsComma(event.target.value))
  }

  useEffect(() => {
    setNewBudget(budget / 100)
  }, [budget])

  useEffect(() => {
    if (!subscriptionStatus) {
      return
    }
    if (subscriptionStatus && (redirectStatus === "succeeded")) {
      if (subscriptionStatus === "budget_set") {
        setPaymentConfirmationPending()
      } else {
        setSearchParams({})
      }
    }
  }, [redirectStatus, subscriptionStatus])

  const redirect = (url) => {
    window.location.href = url
  }

  const onBillingClick = () => {
    setLoading(true)
    fetchBillingData({ redirect })
  }

  const handleSuspendFormSubmit = () => {
    const monthsText = (suspensionMonths === "1") ? "one month" : `${suspensionMonths} months`
    confirm({
      description: `Are you sure you want to pause monthly donations for ${monthsText}?`,
      confirmationText: "Yes",
      cancellationText: "No",
    })
      .then(() => {
        setShowSuspendForm(false)
        setLoading(true)
        const onDone = () => {
          setLoading(false)
        }
        const onSuccess = () => {
          setEditBudget(false)
        }
        const params = { budget: { action: "pause", months: suspensionMonths }}
        updateBudget({ params, setErrors, onDone, onSuccess })
      })
  }

  const onCancelClick = () => {
    confirm({
      description: `Are you sure you want to cancel your subscription?`,
      confirmationText: "Yes",
      cancellationText: "No"
    })
      .then(() => {
        setLoading(true)
        const onDone = () => {
          setLoading(false)

        }
        const onSuccess = () => {
          setEditBudget(false)
        }
        const params = { budget: { action: "cancel" }}
        updateBudget({ params, setErrors, onDone, onSuccess })
      })
  }

  const handleSuspensionMonthsChange = (event) => {
    const value = event.target.value
    if (value === "") {
      setSuspensionMonths("")
    }
    const intValue = parseInt(value)
    if ((intValue > 0) && (intValue < 120)) {
      setSuspensionMonths(event.target.value)
    }
  }

  const onSuspendClick = () => {
    setShowSuspendForm((suspendForm) => !suspendForm)
  }

  const onUnpauseClick = () => {
    confirm({
      description: `Are you sure you want to activate your subscription again?`,
      confirmationText: "Yes",
      cancellationText: "No"
    })
      .then(() => {
        setLoading(true)
        const onDone = () => {
          setLoading(false)
        }
        const onSuccess = () => {
          setEditBudget(false)
        }
        const params = { budget: { action: "unpause" }}
        updateBudget({ params, setErrors, onDone, onSuccess })
      })
  }

  const handleCreateBudgetFormSubmit = () => {
    setLoading(true)
    const onDone = () => {
      setLoading(false)
    }
    const params = { budget: { value: newBudgetAsCents }}
    createBudget({ params, setErrors, onDone })
  }

  const handleUpdateBudgetFormSubmit = () => {
    confirm({
      description: `Are you sure you want to change your budget to ${newBudgetAsCurrency}?`,
      confirmationText: "Yes",
      cancellationText: "No"
    })
      .then(() => {
        setLoading(true)
        const onDone = () => {
          setLoading(false)
        }
        const onSuccess = () => {
          setEditBudget(false)
        }
        const params = { budget: { value: newBudgetAsCents }}
        updateBudget({ params, setErrors, onDone, onSuccess })
      })
      .catch(() => {

      });
  }

  const hasBudget = budget && (budget > 0.0)

  const hasError = (field) => {
    return errors && errors[field]
  }
  const errorText = (field) => {
    return errors && errors[field] && errors[field].join(", ")
  }
  const editBudgetSwitch = () => {
    setEditBudget((editBudget) => !editBudget)
  }

  const missingBudget = !budget

  const newOrCurrentBudget = (!hasBudget || editBudget) ? newBudgetAsCents : budget
  const missingNewOrCurrentBudget = !newOrCurrentBudget

  const feeAmountNumber = missingNewOrCurrentBudget ? 0 : calculateFeeInCents(newOrCurrentBudget)
  const actualBudgetNumber = missingNewOrCurrentBudget ? 0 : calculateActualBudgetInCents(newOrCurrentBudget)

  const numericBudget = missingNewOrCurrentBudget ? centsAsCurrency(0) : centsAsCurrency(budget)
  const numericNewOrCurrentBudget = missingNewOrCurrentBudget ? centsAsCurrency(0) : centsAsCurrency(newOrCurrentBudget)
  const feeAmount = missingNewOrCurrentBudget ? centsAsCurrency(0) : centsAsCurrency(feeAmountNumber)
  const actualBudget = missingNewOrCurrentBudget ? centsAsCurrency(0) : centsAsCurrency(actualBudgetNumber)

  const stripeImagePath = isDarkModeEnabled ? "/powered_by_stripe_white.svg" :
"/powered_by_stripe_black.svg"

  const subscriptionInactive = subscriptionStatus in ["new", "budget_set", "paused"]

  return (
  <>
    <Grid container sx={{px: 2}}>
      <Grid item md={6} xs={12} sx={{ pr: 2, mb: 2 }}>
        { (subscriptionStatus === "paused") ? (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Your subscription is paused. The next donation is scheduled for {<Moment format="MMMM Do YYYY">{nextPaymentDate}</Moment>}
          </Alert>
        ) : "" }
        { (subscriptionStatus === "active") ? (
          <Typography sx={{ mb: 2 }}>
            You have subscribed to monthly donations to nonprofits you selected. The next donation is scheduled for {<Moment format="MMMM Do YYYY">{nextPaymentDate}</Moment>}.
          </Typography>
        ) : (
          <Typography sx={{ mb: 2 }}>
            Set your monthly budget and we'll distribute it among the nonprofits that you chose.
          </Typography>
        ) }
        <Typography sx={{ mb: 2 }}>
          Our fee is plain and simple: <strong>5<span> %</span> + 0,50 € </strong>
        </Typography>

        { subscriptionInactive && !missingBudget ? (
          <Typography sx={{ mb: 2 }}>
            You currently don't have a subscription set, but you set the budget before, so here are the numbers for when the subscription becomes active.
          </Typography>
        ) : ""}

        { hasBudget ? (
          <Paper sx={{ boxShadow: "none" }}>
            <FormControl>
              { missingNewOrCurrentBudget ? (
                <Typography>
                  Your budget: { numericBudget }
                </Typography>
              ) : (
                <>
                  { missingBudget ? "" : (
                    <Typography sx={{ width: "300px", textAlign: "right", mb: 1}}>
                      Your current budget: { numericBudget }
                    </Typography>
                  )}
                  { editBudget ? (
                    <Typography sx={{ width: "300px", textAlign: "right"}}>
                      Your new budget: { numericNewOrCurrentBudget }
                    </Typography>
                  ) : ""}
                  <Typography sx={{ width: "300px", textAlign: "right"}}>
                    Fee amount: { feeAmount }
                  </Typography>
                  <Typography sx={{ width: "300px", textAlign: "right"}}>
                    For distribution: { actualBudget }
                  </Typography>
                </>
              )}
              { editBudget ? (
                <TextField
                  sx={{ mt: 2 }}
                  required
                  value={toEuComma(newBudget)}
                  onChange={handleBudgetChange}
                  id="budget-required"
                  label="Update Your Budget"
                  error={hasError("value_in_cents")}
                  helperText={errorText("value_in_cents")}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
              ) : "" }
              { editBudget ? (
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Button variant="outlined" sx={{ mt: 2 }} onClick={editBudgetSwitch}>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={handleUpdateBudgetFormSubmit} disabled={loading || (budget === newBudgetAsCents)}>Update</Button>
                  </Grid>
                </Grid>
              ) : (
                <Button variant="outlined" sx={{ mt: 1 }} onClick={editBudgetSwitch}>Change</Button>
              ) }
            </FormControl>
          </Paper>
        ) : (
          <Paper sx={{ boxShadow: "none" }}>
            <FormControl>
              <Typography sx={{ width: "300px", textAlign: "right"}}>
                Your budget: { numericNewOrCurrentBudget }
              </Typography>
              <Typography sx={{ width: "300px", textAlign: "right"}}>
                Fee amount: { feeAmount }
              </Typography>
              <Typography sx={{ width: "300px", textAlign: "right"}}>
                For distribution: { actualBudget }
              </Typography>

              <TextField
                required
                value={toEuComma(newBudget)}
                onChange={handleBudgetChange}
                id="budget-required"
                label="Set Your Budget"
                error={hasError("value_in_cents")}
                helperText={errorText("value_in_cents")}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
              />
              <Button variant="contained" sx={{ mt: 2 }} onClick={handleCreateBudgetFormSubmit} disabled={loading}>Save</Button>
            </FormControl>
          </Paper>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper sx={{ backgroundColor: "rgba(25, 118, 210, 0.08)", p: 2 }} elevation={0} >
          { (subscriptionStatus === "budget_set") && !redirectStatus ? (
            showStripeForm ? (
              <StripePayment />
            ) : (
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                  <img alt="Stripe" style={{ margin: "auto" }} src={stripeImagePath} width="200"/>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    We cooperate with Stripe to ensure maximum payment security. We will not store any data from your credit card.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 2 }}>
                  <Button variant="contained" onClick={() => setShowStripeForm(true)}>
                    Pay with Stripe
                  </Button>
                </Grid>
              </Grid>
            )
          ) : (
            (subscriptionStatus === "new") ? (
              <Grid container>
                <Grid item xs={12}>
                  <img alt="Stripe" style={{ margin: "auto" }} src={stripeImagePath} width="200"/>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    We cooperate with Stripe to ensure maximum payment security. We will not store any data from your credit card.
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    Your subscription is active. Feel free to review your billing records
                  </Typography>
                  <Button variant="outlined" onClick={onBillingClick} sx={{ mt: 1 }}>Billing</Button>
                </Grid>
                { (subscriptionStatus === "paused") ? (
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography>
                      Your subscription is paused. You can activate it right now, or change for how many months you want it to be paused.
                    </Typography>
                    { showSuspendForm ? (
                      <TextField
                        sx={{ mt: 2 }}
                        required
                        value={suspensionMonths}
                        onChange={handleSuspensionMonthsChange}
                        id="suspension-required"
                        label="Number of months"
                        error={hasError("suspenseMonths")}
                        helperText={errorText("suspenseMonths")}
                      />
                    ) : "" }
                    { showSuspendForm ? (
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Button variant="outlined" sx={{ mt: 2 }} onClick={onSuspendClick}>Cancel</Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" sx={{ mt: 2 }} onClick={handleSuspendFormSubmit} disabled={loading || (suspensionMonths === "")}>
                            {(subscriptionStatus === "paused") ?
                              "Change pause period" :
                              "Pause subscription"}
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Button variant="outlined" onClick={onSuspendClick} sx={{ mt: 1 }}>Change pause time</Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" onClick={onUnpauseClick} sx={{ mt: 1 }}>Activate again</Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography>
                      You can pause your subscription for a month or a couple of months. Click the button below to see all the options.
                    </Typography>
                    { showSuspendForm ? (
                      <TextField
                        sx={{ mt: 2 }}
                        required
                        value={suspensionMonths}
                        onChange={handleSuspensionMonthsChange}
                        id="suspension-required"
                        label="Number of months"
                        error={hasError("suspenseMonths")}
                        helperText={errorText("suspenseMonths")}
                      />
                    ) : "" }
                    { showSuspendForm ? (
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Button variant="outlined" sx={{ mt: 2 }} onClick={onSuspendClick}>Cancel</Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" sx={{ mt: 2 }} onClick={handleSuspendFormSubmit} disabled={loading || (suspensionMonths === "")}>Pause subscription</Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Button variant="outlined" onClick={onSuspendClick} sx={{ mt: 1 }}>Pause</Button>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography>
                    You can also cancel your subscription completely at any point.
                  </Typography>
                  <Button variant="contained" color="error" onClick={onCancelClick} sx={{ mt: 1 }}>Cancel subscription</Button>
                </Grid>
              </Grid>
            )
          ) }
        </Paper>
      </Grid>
    </Grid>
    <Grid container sx={{px: 2}}>
      <Grid item md={6} xs={12} sx={{ pr: 2, mb: 2 }}>
        <h3>My donation receipt</h3>
        <Typography sx={{ fontStyle: "italic", mb: 2, mt: -3 }}>
          (only for use with German taxes<Link href="#tax-excempt"> * </Link>)
        </Typography>
        <Typography>
          For donations below 200€ a year, you can use your bank transfer receipts for your tax refund. If you require a donations receipt, please e-mail teddy@cordialcredit.org
        </Typography>
        <Typography id="tax-excempt" variant="subtitle2" sx={{ fontStyle: "italic", fontWeight: "bold", mt: 2 }}>
          (*) Your donations are tax-exempt in Germany
        </Typography>
        <Typography variant="body2">
          Donations via Cordial Credit are tax-deductible.
          This only applies to your donations, not to our fee.
        </Typography>
        <Typography variant="body2">
          Cordial gUG (haftungsbeschränkt) with its registered office in Bremen
          is a non-profit corporation. In a notice of tax-exemption
          dated 14 June 2023, the Bremen tax office recognised
          that the company exclusively and directly
          serves charitable purposes within the meaning of Sections 51 ff. AO.
          It is therefore exempt from corporation tax pursuant
          to Section 5 para. 1 no. 5 and from trade tax pursuant
          to Section 3 no. 6 GewStG.
        </Typography>
        <Typography variant="body2">
          Tax number: 60 / 147 / 11838
        </Typography>
      </Grid>
    </Grid>
  </>
  )
}
